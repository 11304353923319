import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';
import { PolicyRoutingModule } from './policy-routing.module';
import { PolicyComponent } from './components/tabs/policy/policy.component';
import { FormTabModule } from 'projects/form-tab/src/public-api';

import { MatFormFieldModule, MatInputModule, MatDialogModule, MatTableModule, MatButtonModule, MatMenuModule, MatToolbarModule, MatChipsModule, MatIconModule, MatTabsModule, MatExpansionModule, MatSidenavModule, MatTooltipModule, MatTreeModule, MatCardModule, MatSelectModule, MatCheckboxModule, MatSnackBarModule, MatProgressSpinnerModule, } from '@angular/material';

import { UnderwritingRulesComponent } from './components/core/underwriting-rules/underwriting-rules.component';
import { PolicyErrorsComponent } from './components/policy-errors/policy-errors.component';
import { PolicyEditorComponent } from './core/policy-editor.component';
import { LocationContentComponent } from './components/tabs/location/location-content.component';
import { RatingComponent } from './components/tabs/rating/rating.component';
import { NotesComponent } from './components/tabs/notes/notes.component';
import { AddNoteDialogComponent } from './components/tabs/notes/dialogs/add-note-dialog/add-note-dialog.component';
import { ClaimsContentComponent } from './components/tabs/claims/claims-content.component';
import { InterestsComponent } from './components/tabs/interests/interests.component';
import { RiskDetailsComponent } from './components/tabs/location/subtab/risk-details/risk-details.component';
import { HeatingInfoComponent } from './components/tabs/location/subtab/heating-info/heating-info.component';
import { BuildingDetailsComponent } from './components/tabs/location/subtab/building-details/building-details.component';
import { BuildingCoveragesComponent } from './components/tabs/location/subtab/building-coverages/building-coverages.component';
import { BillingComponent } from './components/tabs/billing/billing.component';
import { CoverageDetailsComponent } from './components/tabs/location/subtab/coverage-details/coverage-details.component';
import { OutbuildingsComponent } from './components/tabs/location/subtab/outbuildings/outbuildings.component';
import { PolicyHeaderComponent } from './components/header/policy-header/policy-header.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { AddressComponent } from './components/tabs/location/subtab/address/address.component';
import { DeleteNodeDialog } from './components/tabs/location/dialogs/delete-node-dialog.component';
import { ErrorDialogComponent } from './components/tabs/rating/dialogs/error-dialog/error-dialog.component';
import { InsuredInformationComponent } from './components/tabs/policy/subtabs/insured-information/insured-information.component';
import { PolicyholderComponent } from './components/tabs/policy/subtabs/policyholder/policyholder.component';
import { MatTreeComponent } from './shared/components/mat-tree/mat-tree.component';
import { NestedMatTreeComponent } from './shared/components/nested-mat-tree/nested-mat-tree.component';
import { FarmRevenueComponent } from './components/tabs/policy/subtabs/farm-revenue/farm-revenue.component';
import { PolicyCoverageComponent } from './components/tabs/policy/subtabs/policy-coverage/policy-coverage.component';
import { FarmActivitiesComponent } from './components/tabs/policy/subtabs/farm-activities/farm-activities.component';
import { FormComponent } from './shared/components/form/form.component';
import { DialogForm } from './shared/components/dialog-form/dialog-form.component';
import { NgxMaskModule } from 'ngx-mask';
import { LiabilityComponent } from './components/tabs/liability/liability/liability.component';
import { CoverageTableComponent } from './shared/components/coverage-table/coverage-table.component';
import { DebugDataComponent, DebugDialog } from './components/debug-data/debug-data.component';
import { ClientComponent } from './components/tabs/client/client.component';
import { NestedSubtabFormComponent } from './shared/components/nested-subtab-form/nested-subtab-form.component';
import { ClaimsFormComponent } from './components/tabs/claims/claims-form/claims-form.component';
import { InterestsFormComponent } from './components/tabs/interests/interests-form/interests-form.component';
import { BasicDialogComponent } from './shared/components/basic-dialog/basic-dialog.component';
import { InterestAllocationTableComponent } from './shared/components/interest-allocation-table/interest-allocation-table.component';
import { ClientInformationComponent } from './components/tabs/client/subtabs/client-information/client-information.component';
import { DialogSearchComponent } from './shared/components/dialog-search/dialog-search.component';
import { ClientSearchComponent } from './components/shared/client-search/client-search.component';
import { ExposureInformationComponent } from './components/tabs/location/subtab/exposure-information/exposure-information.component';
import { LiabilityCoveragesComponent } from './components/tabs/location/subtab/liability-coverages/liability-coverages.component';
import { RatingCoverageTableComponent } from './components/tabs/rating/rating-coverage-table/rating-coverage-table.component';
import { ScheduleItemInformationComponent } from './components/tabs/location/subtab/schedule-item-information/schedule-item-information.component';
import { SupplementInformationComponent } from './components/tabs/location/subtab/supplement-information/supplement-information.component';
import { WatercraftInformationComponent } from './components/tabs/location/subtab/watercraft-information/watercraft-information.component';
import { UnderwriterReviewDialogComponent } from './shared/components/underwriter-review-dialog/underwriter-review-dialog.component';
import { OutbuildingDetailsComponent } from './components/tabs/location/subtab/outbuilding-details/outbuilding-details.component';
import { BrokerReportComponent } from './components/tabs/policy/subtabs/broker-report/broker-report.component';
import { BinsAndSilosDetailsComponent } from './components/tabs/location/subtab/bins-and-silos-details/bins-and-silos-details.component';
import { BinsAndSilosCoverageDetailsComponent } from './components/tabs/location/subtab/bins-and-silos-coverage-details/bins-and-silos-coverage-details.component';
import { GrainDetailsComponent } from './components/tabs/location/subtab/grain-details/grain-details.component';
import { GrainElevatorDetailsComponent } from './components/tabs/location/subtab/grain-elevator-details/grain-elevator-details.component';


@NgModule({
  declarations: [
    PolicyComponent,
    UnderwritingRulesComponent,
    PolicyErrorsComponent,
    PolicyEditorComponent,
    LocationContentComponent,
    RatingComponent,
    RatingCoverageTableComponent,
    ClaimsContentComponent,
    NotesComponent,
    AddNoteDialogComponent,
    InterestsComponent,
    RiskDetailsComponent,
    BuildingDetailsComponent,
    BuildingCoveragesComponent,
    BillingComponent,
    HeatingInfoComponent,
    ScheduleItemInformationComponent,
    SupplementInformationComponent,
    WatercraftInformationComponent,
    OutbuildingDetailsComponent,
    DeleteNodeDialog,
    CoverageDetailsComponent,
    OutbuildingsComponent,
    PolicyHeaderComponent,
    NavBarComponent,
    AddressComponent,
    ErrorDialogComponent,
    InsuredInformationComponent,
    PolicyholderComponent,
    MatTreeComponent,
    NestedMatTreeComponent,
    FarmRevenueComponent,
    PolicyCoverageComponent,
    FarmActivitiesComponent,
    FormComponent,
    DialogForm,
    DebugDialog,
    LiabilityComponent,
    CoverageTableComponent,
    DebugDataComponent,
    ClientComponent,
    NestedSubtabFormComponent,
    ClaimsFormComponent,
    InterestsFormComponent,
    BasicDialogComponent,
    UnderwriterReviewDialogComponent,
    InterestAllocationTableComponent,
    ClientInformationComponent,
    DialogSearchComponent,
    ClientSearchComponent,
    ExposureInformationComponent,
    LiabilityCoveragesComponent,
    BrokerReportComponent,
    BinsAndSilosDetailsComponent,
    BinsAndSilosCoverageDetailsComponent,
    GrainDetailsComponent,
    GrainElevatorDetailsComponent
  ],
  imports: [
    CommonModule,
    PolicyRoutingModule,
    CoreModule,
    SharedModule,
    FormTabModule,
    MatTableModule,
    MatButtonModule,
    MatMenuModule,
    MatTabsModule,
    MatExpansionModule,
    MatSidenavModule,
    MatIconModule,
    MatChipsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatSelectModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    NgxMaskModule.forRoot({
      showMaskTyped: true
    }),
  ],
  entryComponents: [
    AddNoteDialogComponent,
    DeleteNodeDialog,
    ErrorDialogComponent,
    DialogForm,
    DebugDialog,
    DialogSearchComponent,
    BasicDialogComponent,
    UnderwriterReviewDialogComponent
  ],
  providers: [
  ],
})
export class PolicyModule { }
