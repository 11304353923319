import { PolicyService } from '@modules/policy/policy.service';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { FormTabService } from 'projects/form-tab/src/public-api';
import { JsonFormFetcherService } from './json-form-fetcher.service';
import { PolicyDataService } from './policy-data.service';
import { DynamicDropdownService } from './dynamic.dropdown.service';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  private isListByFormName: { [s: string]: boolean } = {};

  constructor(
    private PolicyService: PolicyService,
    private FormTabService: FormTabService,
    private JsonFormFetcherService: JsonFormFetcherService,
    private PolicyDataService: PolicyDataService,
    private DynamicDropdownService: DynamicDropdownService,
  ) { }

  initForm({
    subtab,
    formid,
    subTabList,
    policyID,
    shouldCache
  }) {
    const subtabConfig: any = subTabList.find((item: { [x: string]: any; }) => item.formName == subtab);
    this.isListByFormName[subtabConfig.formName] = subtabConfig.isList;

    // TODO: Rework how underwriting rules are calculated.
    //       Right now, we depend on re-fetching the data in ALL cases for when a change affects
    //       underwriting rules. This short circuits that process, forcing us to display stale data.
    // if (this.doesFormExist(formid) && shouldCache === true) {
    //   return Number(formid);
    // }

    const getFormValues: Observable<any> = this.getFormValues(policyID, formid);
    const appendDropdownValuesToConfig = this.insertFormLookups(policyID, formid, subtabConfig.shortRoute)

    return forkJoin([appendDropdownValuesToConfig, getFormValues]).pipe(
      mergeMap(result => {
        const [appendedDropdownValuesConfig, formValues] = result;
        this.PolicyDataService.insertCache(formid, formValues["data"]);
        this.PolicyDataService.updateUnderWritingRules(formValues["opinions"]);
        return this.FormTabService.initForm(JSON.stringify(appendedDropdownValuesConfig["config"]), JSON.stringify(formValues["data"]))
      })
    )
  }

  initBillingForm({ formName, policyID, formData }) {
    return this.insertFormLookups(policyID, null, formName).pipe(
      mergeMap(result => {
        return this.FormTabService.initForm(JSON.stringify(result["config"]), JSON.stringify(formData))
      })
    )
  }

  setForm({ formid, form, orderedFormJson }) {
    this.PolicyService.setForm(formid, form);
    this.PolicyService.setFormJson(formid, orderedFormJson);
  }

  getFormValues(policyID, formid) {
    return this.PolicyDataService.getData(policyID, formid);
  }

  doesFormExist(formid: number): boolean {
    const form = this.PolicyService.getForm(formid);
    return form != null && Object.keys(form.controls).length > 0;
  }

  private insertFormLookups(policyID, nodeID, formName) {
    return this.JsonFormFetcherService.getConfig(policyID, formName)
      .pipe(
        mergeMap(config => {
          return this.DynamicDropdownService.appendSelectValuesToConfig(config, policyID, nodeID).pipe(map(() => ({ config })))
        })
      )
  }
}
