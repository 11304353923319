import { handleValidations } from '@modules/policy/shared/rules/handle-validations.rules';
import { LocationService } from '@modules/policy/services/location/location.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NestedSubtabFormComponent } from '@modules/policy/shared/components/nested-subtab-form/nested-subtab-form.component';
import { PolicyDataService } from '@modules/policy/services/shared/policy-data.service';
import { FieldLookupService } from 'projects/form-tab/src/lib/services/lookup.service';

@Component({
  selector: 'app-building-details',
  templateUrl: './building-details.component.html',
  styleUrls: ['./building-details.component.scss']
})
export class BuildingDetailsComponent implements OnInit {
  form: FormGroup;
  @ViewChild("formComponent", { static: false }) formComponent: NestedSubtabFormComponent;

  constructor(
    private policyDataService: PolicyDataService,
    private fieldLookupService: FieldLookupService,
    private locationService: LocationService
  ) { }

  saveBeforeDeactivate() {
    return this.formComponent.saveBeforeDeactivate();
  }

  showSaveSnackbar() {
    this.formComponent.showSaveSnackbar();
    this.formComponent.updateNavigationTree();
  }

  ngOnInit() { }

  initForm(form: FormGroup) {
    this.form = form;
    if (this.formComponent) handleValidations(this.formComponent.formJson, this.form);
    this.handleLivestockLookup();
  }

  handleLivestockLookup() {
    const structureTypeControl = this.form.controls.BuildingDetails.get('OutbuildingStructureTypeCd');
    structureTypeControl.valueChanges.subscribe(value => {
      if (structureTypeControl.value != null && structureTypeControl.value != "CLEAR_VALUES") {
        const policyId = this.policyDataService.getPolicyID();
        this.locationService.gettbl_FPRO_FarmBuilding_PurposeLookups(policyId).subscribe((res) => {
          const lookup = Object.keys(res).filter(lookup => lookup.replace(/[\s|\-]/, "").includes(structureTypeControl.value.replace(/[\s|\-]/, "")));
          if (lookup) {
            this.fieldLookupService.updateSelectValues("OutbuildingLivestockTypeCd", res[lookup[0]]);
            this.form.controls.BuildingDetails.get("OutbuildingLivestockTypeCd").setValue(null);
          }
        });
      }
    });
  }
}
